<ngb-progressbar type="primary" [value]="100" [striped]="true" *ngIf="cargando">... cargando ...</ngb-progressbar>

<div class="hk-pg-wrapper" *ngIf="configuraciones != undefined">
  <div class="container">
    <div class="row">
      <div class="col">


        <section class="hk-sec-wrapper" *ngIf="!cargando">

          <!-- Paso 5. Firma de la instancia -->
          <div class="row" *ngIf="solicitud.solicitud.idEstado == 1">
            <div class="col-sm">
              <h2 class="col-8 mb-20">
                Paso 5. Firma de la Instancia General
              </h2>
              <p class="mb-20">
                Para firmar la instancia, antes deberá obtener la clave firma. Para ello haga clic en <strong>OBTENER CLAVE FIRMA</strong>. A continuación, el sistema le enviará una clave
numérica aleatoria a la dirección electrónica declarada, que deberá introducir en la casilla correspondiente. Cuando finalice haga clic en <strong>SIGUIENTE</strong>.
              </p>
            </div>
          </div>

          <!-- Paso 6. Resguardo -->
          <div class="row" *ngIf="solicitud.solicitud.idEstado == 2">
            <div class="col-sm">
              <h2 class="col-8 mb-20">
                Paso 6. Resguardo
              </h2>
              <p class="mb-20">
                Puede descargar el resguardo de su Solicitud, desde el botón correctamente.
              </p>
            </div>
          </div>
        </section>

        <div class="row" *ngIf="!cargando && solicitud.solicitud != null && solicitud.solicitud.id == 0">
          <div class="col">
            <div class="card border-info shadow-lg">
              <div class="card-body text-info">
                <p class="card-text">
                  Va a proceder a firmar la presentación de la instancia general. Para ello haga clic en el siguiente botón. Una vez que se envíe la clave de operaciones, podrá también obtener el <strong>borrador</strong> de la solicitud.
                </p>
                <button class="btn btn-info btn-block" (click)="ObtenerClave()">
                  OBTENER CLAVE OPERACIONES
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Datos de la solicitud -->
        <div class="row" *ngIf="!cargando && solicitud.solicitud != null && solicitud.solicitud.id > 0">
          <div class="col">
            <div class="card border-success">
              <div class="card-body">
                <h4 class="card-title text-success">
                  Nueva solicitud de Registro Electrónico
                </h4>
                <h6 *ngIf="solicitud.solicitud != null && solicitud.solicitud.localizador.length > 0">
                  Localizador: <b>{{solicitud.solicitud.localizador}}</b>
                </h6>
              </div>
              <ul class="list-group list-group-flush" *ngIf="_persistente.solicitud != null">
                <li class="list-group-item text-success">
                  <strong>Descripción</strong>: {{_persistente.solicitud.descripcion}}
                </li>
                <li class="list-group-item text-success" *ngIf="!_persistente.solicitud.paraSecretariaGeneral">
                  <strong>Órgano o comité</strong>: {{_persistente.tipoOrgano}}
                </li>
                <li class="list-group-item text-success" *ngIf="!_persistente.solicitud.paraSecretariaGeneral">
                  <strong>Departamento</strong>: {{_persistente.departamento}}
                </li>
                <li class="list-group-item text-success" *ngIf="_persistente.solicitud.paraSecretariaGeneral">
                  <strong>Solicitud para Secretaría General</strong>
                </li>
              </ul>
              <div class="card-body text-success">
                <h5 class="card-title text-success" *ngIf="_persistente.ArchivosPDF().length > 0 || _persistente.OtrosArchivos().length > 0">
                  Archivos adjuntos
                </h5>
                <div class="table-wrap" *ngIf="_persistente.ArchivosPDF().length > 0 || _persistente.OtrosArchivos().length > 0">
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th class="text-success">Nombre</th>
                          <th class="text-success">Tipo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let f of _persistente.ArchivosPDF()">
                          <td class="text-secondary">{{f.nombre}}</td>
                          <td class="text-secondary">{{f.mimeType}}</td>
                        </tr>
                        <tr *ngFor="let f of _persistente.OtrosArchivos()">
                          <td class="text-secondary">{{f.nombre}}</td>
                          <td class="text-secondary">{{f.mimeType}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <button class="btn btn-success btn-block" (click)="Borrador()" *ngIf="solicitud.solicitud.idEstado == 1">
                  BORRADOR DE LA SOLICITUD
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Interesado -->
        <div class="row" *ngIf="!cargando && solicitud.solicitud != null && solicitud.solicitud.id > 0">
          <div class="col">
            <div class="card border-info">
              <div class="card-body">
                <h6 class="text-info">
                  Interesado/a Presentador/a
                </h6>
                <h4 class="text-info" *ngIf="_persistente.persona != null">
                  {{_persistente.persona.nombre}} {{_persistente.persona.apellido1}} {{_persistente.persona.apellido2}} ({{_persistente.persona.identificador}})
                </h4>
              </div>
            </div>
          </div>
        </div>

        <!-- Formulario para incluir la firma de operaciones -->
        <div class="row" *ngIf="!cargando && solicitud.solicitud != null && solicitud.solicitud.id > 0 && solicitud.solicitud.idEstado == 1">
          <div class="col">
            <div class="card border-info shadow-lg">
              <div class="card-body text-info">
                <p>
                  Por favor, introduzca la clave firma recibida en su correo electrónico y luego haga click en <strong>FIRMAR Y REGISTRAR</strong>
                </p>

                <form [formGroup]="formValidar" (ngSubmit)="Validar()">
                  <div class="row">
                    <div class="col form-group">
                      <input type="text" class="form-control" formControlName="firma">
                      <div *ngIf="firma.hasError('required')" class="ng-invalid">
                        La firma es obligatoria
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <button class="btn btn-info btn-block" type="submit">
                        FIRMAR Y REGISTRAR
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- Resguardo de la solicitud (solicitud validada) -->
        <div class="row" *ngIf="!cargando && solicitud.solicitud != null && solicitud.solicitud.id > 0 && solicitud.solicitud.idEstado == 2">
          <div class="col">
            <div class="card border-success">
              <div class="card-body">
                <h4 class="card-title text-success">
                  Solicitud de Registro electrónico <b>REALIZADA</b>
                </h4>
                <p>
                  Pulse en el siguiente botón para descargar el <b>resguardo</b> de su petición.
                </p>
                <p>
                  <button class="btn btn-success btn-block" (click)="Resguardo()">
                    RESGUARDO
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
