import { HttpClient } from '@angular/common/http';
import { HotToastService } from '@ngneat/hot-toast';
import { IConfiguraciones } from './IConfiguraciones';

export interface IExceptionWeb {
  error: IErrorExceptionWeb;
  message: string;
  name: string;
  ok: boolean;
  status: number;
  statusText: string;
  url: string;
}

export interface IErrorExceptionWeb {
  detail: string;
  status: number;
  title: string;
  traceId: string;
}

export class PageBase {

  //#region Métodos de la clase de
  public cargando: boolean = false;
  public configuraciones: IConfiguraciones;
  private urlSettings: string = 'assets/settings.json';
  //#endregion

  constructor(
    public http: HttpClient,
    public toastService: HotToastService
  ) {
    const url: string = this.urlSettings + '?v=' + new Date().getTime();

    this.cargando = true;
    this.http.get(url).subscribe((data: IConfiguraciones) => {
      this.configuraciones = data;
      this.cargando = false;
    });
  }

  //#region Métodos de la clase

  public Error(datos: IExceptionWeb | any): void {
    const excepcion = <IExceptionWeb>datos;

    this.cargando = false;

    if (excepcion != null && excepcion.error !== undefined && excepcion.error.title.length > 0) {
      this.toastService.error(excepcion.error.title, {
        autoClose: false,
        dismissible: true,
        className: 'bg-red-light-1 text-white'
      });
    } else {
      this.toastService.error('Error general en la aplicación', {
        autoClose: false,
        dismissible: true,
        className: 'bg-red-light-1 text-white'
      });
    }
  }

  /**
   * Muestra un mensaje emergente (toast) con un aviso (warning)
   * @param texto Texto a mostrar en el mensaje
   * @param tiempo Duración del mensaje, por defecto 1 segundo y medio
   */
  public MensajeAviso(texto: string, tiempo: number = 9000): void {
    this.toastService.warning(texto, {
      className: 'bg-orange-light-1 text-white',
      duration: tiempo
    });
  }

  /**
   * Muestra un mensaje emergente (toast) con una operación realiada correctamente
   * @param texto Texto a mostrar en el mensaje
   * @param tiempo Duración del mensaje, por defecto 1 segundo
   */
  public MensajeExito(texto: string, tiempo: number = 6000): void {
    this.toastService.success(texto, {
      className: 'bg-green-light-1 text-white',
      duration: tiempo
    });
  }

  //#endregion
}
