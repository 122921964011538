<ngb-progressbar type="primary" [value]="100" [striped]="true" *ngIf="cargando">... cargando ...</ngb-progressbar>

<div class="hk-pg-wrapper" *ngIf="configuraciones != undefined">
  <div class="container">
    <div class="row">
      <div class="col">


        <section class="hk-sec-wrapper">
          <div class="row">
            <div class="col-sm">
              <h2 class="col-8 mb-20">
                Paso 3. Legal
              </h2>
              <h4 class="mb-20">
                Declaración responsable
              </h4>
              <p class="mb-20">
                Debe aceptar todas las declaraciones responsables para poder continuar.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <form [formGroup]="formLegal" (ngSubmit)="Siguiente()">
                <div class="row">
                  <div class="col form-group">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="chkCierto" formControlName="cierto" required>
                      <label class="form-check-label" for="chkCierto">
                        Que son ciertos los datos que figuran en la presente solicitud y en la documentación que junto a ella presento.
                      </label>
                      <div *ngIf="cierto.hasError('required')" class="ng-invalid">
                        Es obligatorio aceptar estas condiciones
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-20">
                  <div class="col form-group">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="chkPresento" formControlName="presento" required>
                      <label class="form-check-label" for="chkPresento">
                        Que presento la documentación que se expone.
                      </label>
                      <div *ngIf="presento.hasError('required')" class="ng-invalid">
                        Es obligatorio aceptar estas condiciones
                      </div>
                    </div>
                  </div>
                </div>

                <hr>
                <div class="row">
                  <div class="col">
                    <h4 class="mb-20">
                      Información legal
                    </h4>
                    <p class="mb-20">
                      En cumplimiento de la Legislación en materia de Protección de Datos de Carácter Personal se le informa que los datos de carácter personal que facilite en este formulario, previa autorización expresa del interesado, quedarán registrados en el fichero correspondiente al procedimiento tramitado, titularidad de la {{configuraciones.federacion.nombre}}, cuya finalidad es el registro y la gestión de la solicitud formulada. El órgano responsable del fichero es la {{configuraciones.federacion.nombre}} y la persona interesada podrá ejercer los derechos de acceso, rectificación, cancelación y oposición previstos en la Ley mediante escrito dirigido a la Federación.
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <div class="btn-group btn-block">
                      <button class="btn btn-secondary" (click)="Anterior()" type="button">
                        ANTERIOR
                      </button>
                      <button class="btn btn-primary" type="submit" [disabled]="formLegal.invalid">
                        SIGUIENTE
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

      </div>
    </div>
  </div>
</div>
