<div class="hk-pg-wrapper" *ngIf="configuraciones != undefined">
  <div class="container">
    <div class="row">
      <div class="col">

        <section class="hk-sec-wrapper">
          <div class="row">
            <div class="col-sm">
              <h2 class="col-8">
                Instancia general
              </h2>
              <hr>
              <p class="mb-20">
                La instancia general es el formulario mediante el cual los interesados (personas físicas) pueden presentar solicitudes, escritos y
comunicaciones en el Registro General de la {{configuraciones.federacion.nombre}} sobre determinados
procedimientos.
              </p>
              <p class="mb-20">
                Los procedimientos que se pueden realizar a través de la sede electrónica de la {{configuraciones.federacion.nombre}}
se relacionan en el Catálogo de Trámites.
              </p>
              <p class="mb-20">
                <strong class="badge badge-secondary">NOTA 1</strong>: Muchos de los procedimientos precisan de la inclusión en la parte de documentación, de otros formularios
normalizados en formato PDF diferentes a la instancia general. Para presentarlas deberá cumplimentar esos formularios y
guardarlos en formato PDF para luego adjuntarlos en la tramitación electrónica.
              </p>
              <p class="mb-20">
                <strong class="badge badge-secondary">NOTA 2</strong>:  La instancia general se firmará mediante una clave firma que le proporcionará el sistema una vez haya cumplimentado
                el formulario correspondiente. Los documentos que deban adjuntarse a la instancia, podrán venir firmadas de forma electrónica
                o manuscrita. En cualquier caso, la {{configuraciones.federacion.nombre}} se reserva el derecho de requerir al
                interesado en la tramitación del procedimiento para solicitarle que se persona y aporte los documentos originales
              </p>
            </div>
          </div>
        </section>

        <section class="hk-sec-wrapper">
          <div class="row">
            <div class="col-sm">
              <p class="mb-20">
                Para iniciar el trámite haga click en el siguiente botón:
              </p>
              <button class="btn btn-primary btn-block" (click)="IniciarTramite()">
                INICIAR TRÁMITE
              </button>
            </div>
          </div>
        </section>

        <section class="hk-sec-wrapper">
          <div class="row">
            <div class="col-sm">
              <h5 class="mb-20">
                El formulario de la instancia general consta de 6 pasos
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-sm">
              <ol class="list-ol">
                <li class="mb-10">
                  <strong>
                    <i class="fa fa-user"></i>
                    Identificación y
                    <i class="fa fa-envelope"></i>
                    contacto:
                  </strong><br/>
                  Deberá cumplimentar los campos relativos a sus datos personales a fin de identificarse ante la FIFLP, así como deberán aportar, obligatoriamente, una
dirección electrónica a efecto de notificaciones. Cuando finalice haga clic en SIGUIENTE.
                </li>
                <li class="mb-10">
                  <strong>
                    <i class="fa fa-edit"></i> Formulario
                  </strong><br/>
                  Deberá cumplimentar un formulario en el que describirá exactamente la solicitud que pretende llevar a cabo, así como seleccionará el órgano o comité al
                  que desea dirigirse, y dentro de aquel, el departamento que corresponda. Cuando finalice haga clic en SIGUIENTE.
                </li>
                <li class="mb-10">
                  <strong>
                    <i class="fa fa-legal"></i> Legal
                  </strong><br/>
                  Paso destinado a proporcionarle información acerca de las cláusulas de protección de datos, declaraciones responsables y cualquier otra, que sean precisas
en virtud del procedimiento y de la ley. Cuando finalice haga clic en SIGUIENTE.
                </li>
                <li class="mb-10">
                  <strong>
                    <i class="fa fa-paperclip"></i> Adjuntos
                  </strong><br/>
                  Podrá adjuntar la documentación que considere oportuna. Los archivos a adjuntar, no pueden superar los 10 Mbytes cada uno de ellos y puede adjuntar
hasta un límite de 10 archivos (5 archivos en formato PDF y 5 archivos de otro tipo). Deberá ir incorporando los archivos, dependiendo de si son archivos
PDF (primer formulario) u otro tipo de archivos (segundo formulario). Cuando finalice haga clic en SIGUIENTE.
                </li>
                <li class="mb-10">
                  <strong>
                    <i class="fa fa-pencil"></i> Firma
                  </strong><br/>
                  Para firmar la instancia, antes deberá obtener la clave firma. Para ello haga clic en OBTENER CLAVE FIRMA. A continuación, el sistema le enviará una clave
numérica aleatoria a la dirección electrónica declarada, que deberá introducir en la casilla correspondiente. Cuando finalice haga clic en SIGUIENTE
                </li>
                <li class="mb-10">
                  <strong>
                    <i class="fa fa-file-text"></i> Resguardo
                  </strong><br/>
                  Si todos los pasos anteriores, se ha cumplimentado correctamente, en este último paso se muestra el número, fecha y hora de registro. Así mismo, podrá
DESCARGARSE EL RESGUARDO.
                </li>
              </ol>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
