import { SolicitudesRegistroService } from './../../services/SolicitudesRegistroController';
import { Router } from '@angular/router';
import { PersistentesService } from 'src/services/Persistentes';
import { HttpClient } from '@angular/common/http';
import { PageBase } from './../../models/PageBase';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  selector: 'app-firma',
  templateUrl: './firma.component.html',
  styleUrls: ['./firma.component.css']
})
export class FirmaComponent extends PageBase implements OnInit {

  public formValidar: FormGroup;
  public solicitud: ModelosTS.SolicitudesRegistroDTO;

  private _baseUrl: string = "";
  private SolicitudesRegistroService: SolicitudesRegistroService;

  get firma() { return this.formValidar.get('firma'); }

  constructor(
    public _persistente: PersistentesService,
    public http: HttpClient,
    private router: Router,
    public toastService: HotToastService,
    @Inject('BASE_URL') baseUrl: string
  ) {
    super(http, toastService);

    this._baseUrl = baseUrl;;
    this.SolicitudesRegistroService = new SolicitudesRegistroService(http, baseUrl);
  }

  ngOnInit() {
    // Comprobación de datos válidos
    if (this._persistente.persona === undefined || this._persistente.solicitud === undefined) {
      this.router.navigateByUrl('identificacion');
    }

    this.solicitud = {
      archivos: this._persistente.ArchivosPDF(),
      otrosArchivos: this._persistente.OtrosArchivos(),
      persona: this._persistente.persona,
      solicitud: this._persistente.solicitud
    };

    this.Inicializar();
  }

  //#region Métodos de la clase

  public Anterior(): void {
    this.router.navigateByUrl('archivos');
  }

  public Borrador(): void {
    this.http
      .get(
        this._baseUrl + 'solicitudesregistro/borrador/' + this.solicitud.solicitud.localizador,
        {
          responseType: 'blob'
        }
      )
      .toPromise()
      .then((resultado: any) => {
        let anchor: HTMLAnchorElement = document.createElement('a');
        var file = new Blob([resultado], { type: 'application/pdf' });
        anchor.download =
          'Borrador_' + this.solicitud.solicitud.localizador + '.pdf';
        anchor.href = URL.createObjectURL(file);
        anchor.click();
      })
      .catch(error => this.Error(error));
  }

  private Inicializar(): void {
    this.formValidar = new FormGroup({
      firma: new FormControl('', Validators.required)
    });
  }

  /**
   * Guarda los datos de la solicitud actual y genera la clave de operaciones que se enviará por correo electrónico
   */
  public ObtenerClave(): void {
    this.cargando = true;

    this.SolicitudesRegistroService.guardar("", this.solicitud)
      .then((resultado: ModelosTS.SolicitudesRegistroDTO) => {
        this.solicitud = resultado;
        this.cargando = false;

        // Mensaje de envío de correo
        //this.ToastService.show('Se le ha enviado un correo electrónico con la clave de operaciones al correo ' + this.solicitud.persona.email, { classname: 'bg-success text-light', delay: 10000 });
      })
      .catch(error => this.Error(error));
  }

  public Resguardo(): void {
    this.http
      .get(
        this._baseUrl + 'solicitudesregistro/resguardo/' +
        this.solicitud.solicitud.id.toString() + '/' +
        this.solicitud.solicitud.localizador,
        {
          responseType: 'blob'
        }
      )
      .toPromise()
      .then((resultado: any) => {
        const anchor: HTMLAnchorElement = document.createElement('a');
        const file = new Blob([resultado], { type: 'application/pdf' });
        anchor.download =
          'Resguardo_' + this.solicitud.solicitud.localizador + '.pdf';
        anchor.href = URL.createObjectURL(file);
        anchor.click();
      })
      .catch(error => this.Error(error));
  }

  public Validar(): void {
    console.log("Firma: " + this.solicitud.solicitud.nombreClub);
    console.log("Validación del formulario: " + this.formValidar.valid);

    if (this.formValidar.valid && this.solicitud.solicitud.nombreClub === this.firma.value) {
      this.cargando = true;

      this.SolicitudesRegistroService.validar("", this.solicitud)
        .then((resultado: ModelosTS.SolicitudesRegistroDTO) => {
          if (resultado) {
            this.solicitud = resultado;
          }

          this.cargando = false;
        })
        .catch(error => this.Error(error));
    }
  }

  //#endregion
}
