<ngb-progressbar type="primary" [value]="100" [striped]="true" *ngIf="cargando">... cargando ...</ngb-progressbar>

<div class="hk-pg-wrapper" *ngIf="configuraciones != undefined">
  <div class="container">
    <div class="row">
      <div class="col">


        <section class="hk-sec-wrapper">
          <div class="row">
            <div class="col-sm">
              <h2 class="col-8 mb-20">
                Paso 4. Archivos adjuntos
              </h2>
              <h4 class="mb-20">
                Documentación que adjunta
              </h4>
              <p class="mb-20">
                A continuación podrá adjuntar la documentación que considere oportuna. Los archivos a adjuntar, no pueden superar los 10 Mbytes cada uno de ellos y puede adjuntar hasta un límite de 10 archivos (5 archivos en formato PDF y 5 archivos de otro tipo). Deberá ir incorporando los archivos, uno a uno, en el formulario correspondiente, dependiendo de si son archivos PDF (primer formulario) u otro tipo de archivos (segundo formulario).
              </p>
            </div>
          </div>

          <hr>
          <div class="row">
            <div class="col-sm-6">
              <h5 class="mb-20">
                Archivos en formato PDF
              </h5>
              <p class="mb-20">
                Máximo 5 archivos.
              </p>
            </div>
            <div class="col-sm-6">
              <h5 class="mb-20">
                Archivos en formato video, audio o fotografía
              </h5>
              <p class="mb-20">
                Tipos soportados: MOV, MP4, AVI, MPG, MP3, WAV, AIFF, JPG, PNG.<br/>
                Máximo 5 archivos.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="card">
                <div class="card-body">

                  <ngx-dropzone (change)="onSelect($event, 'pdf')" accept="application/pdf">
                    <ngx-dropzone-label>Selecciona el archivo o los archivos a subir.</ngx-dropzone-label>
                  </ngx-dropzone>

                  <div class="table-responsive" *ngIf="_persistente.ArchivosPDF().length > 0">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th colspan="3">Archivos PDF subidos</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th>Nombre</th>
                          <th>Tipo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let f of _persistente.ArchivosPDF()">
                          <td>
                            <button class="btn btn-icon btn-danger btn-icon-style-1 float-right"
                              (click)="onRemove(f)">
                              <span class="btn-icon-wrap">
                                <i class="icon-trash"></i>
                              </span>
                            </button>
                          </td>
                          <td>{{f.nombre}}</td>
                          <td>{{f.mimeType}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card">
                <div class="card-body">

                  <ngx-dropzone (change)="onSelect($event, 'otros')">
                    <ngx-dropzone-label>Selecciona el archivo o los archivos a subir.</ngx-dropzone-label>
                  </ngx-dropzone>

                  <div class="table-responsive" *ngIf="_persistente.OtrosArchivos().length > 0">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th colspan="3">Otros archivos subidos</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th>Nombre</th>
                          <th>Tipo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let f of _persistente.OtrosArchivos()">
                          <td>
                            <button class="btn btn-icon btn-danger btn-icon-style-1 float-right"
                              (click)="onRemove(f)">
                              <span class="btn-icon-wrap">
                                <i class="icon-trash"></i>
                              </span>
                            </button>
                          </td>
                          <td>{{f.nombre}}</td>
                          <td>{{f.mimeType}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <div class="btn-group btn-block">
                <button class="btn btn-secondary" (click)="Anterior()" type="button">
                  ANTERIOR
                </button>
                <button class="btn btn-primary" type="button" (click)="Siguiente()">
                  SIGUIENTE
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
