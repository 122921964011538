import { PageBase } from 'src/models/PageBase';
import { Component, Inject, OnInit } from '@angular/core';
import { PersistentesService } from 'src/services/Persistentes';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent extends PageBase implements OnInit {

  private formLegal: FormGroup;

  get cierto() { return this.formLegal.get('cierto'); }
  get presento() { return this.formLegal.get('presento'); }

  constructor(
    public _persistente: PersistentesService,
    public http: HttpClient,
    private router: Router,
    public toastService: HotToastService,
    @Inject('BASE_URL') baseUrl: string
  ) {
    super(http, toastService);
  }

  ngOnInit() {
    // Comprobación de datos válidos
    if (this._persistente.persona === undefined || this._persistente.solicitud === undefined) {
      this.router.navigateByUrl('identificacion');
    }

    this.Inicializacion();
  }

  // #region Métodos de la clase

  public Anterior(): void {
    this.router.navigateByUrl('formulario');
  }

  private Inicializacion(): void {
    this.formLegal = new FormGroup({
      cierto: new FormControl('', Validators.required),
      presento: new FormControl('', Validators.required)
    });
  }

  public Siguiente(): void {
    if (this.formLegal.valid) {
      this.router.navigateByUrl('archivos');
    }
  }

  // #endregion

}
