import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { PageBase } from 'src/models/PageBase';
import { PersistentesService } from '../../services/Persistentes';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent extends PageBase implements OnInit {

  constructor(
    public _persistente: PersistentesService,
    public http: HttpClient,
    private router: Router,
    public toastService: HotToastService
  ) {
    super(http, toastService);
  }

  ngOnInit() {
    this._persistente.persona = undefined;
  }

  // #region Métodos de la clase de
  public IniciarTramite(): void {
    this.router.navigateByUrl('/identificacion');
  }
  //#endregion

}
