
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

  @Injectable()
  export class SolicitudesRegistroService {
    private http:HttpClient;
    private baseUrl: string;
    constructor(_http: HttpClient, _baseUrl: string) {
      this.http = _http;
      this.baseUrl = _baseUrl;
    }
    
    public borrador = (params:any) : Promise<any> => {
      return this.http.get(this.baseUrl + 'solicitudesregistro/borrador' + params).toPromise()
        .then(result => {
          return result;
        })
        .catch(error => Promise.reject(error));
    }
    
    public guardar = (paramsUrl:any,params:any) : Promise<any> => {
      return this.http.post(this.baseUrl + 'solicitudesregistro/guardar' + paramsUrl, params).toPromise()
        .then(result => {
          return result;
        })
        .catch(error => Promise.reject(error));
    }
    
    public lista = (params:any) : Promise<any> => {
      return this.http.get(this.baseUrl + 'solicitudesregistro/lista' + params).toPromise()
        .then(result => {
          return result;
        })
        .catch(error => Promise.reject(error));
    }
    
    public resguardo = (params:any) : Promise<any> => {
      return this.http.get(this.baseUrl + 'solicitudesregistro/resguardo' + params).toPromise()
        .then(result => {
          return result;
        })
        .catch(error => Promise.reject(error));
    }
    
    public validar = (paramsUrl:any,params:any) : Promise<any> => {
      return this.http.post(this.baseUrl + 'solicitudesregistro/validar' + paramsUrl, params).toPromise()
        .then(result => {
          return result;
        })
        .catch(error => Promise.reject(error));
    }
    
  }

