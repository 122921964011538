
<ngb-progressbar type="primary" [value]="100" [striped]="true" *ngIf="cargando">... cargando ...</ngb-progressbar>

<div class="hk-pg-wrapper" *ngIf="configuraciones != undefined">
  <div class="container">
    <div class="row">
      <div class="col">


        <section class="hk-sec-wrapper">
          <div class="row">
            <div class="col-sm">
              <h2 class="col-8 mb-20">
                Paso 2. Formulario
              </h2>
              <p class="mb-20">
                Indique una <strong>descripción</strong> de la solicitud del Registro Electrónico General que está realizando. También, debe indicar el <strong>órgano, comité o departamento</strong> al que va destinada esta solicitud.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <form [formGroup]="formFormulario" (ngSubmit)="Validar()">
                <div class="row">
                  <div class="col-sm form-group">
                    <label>Descripción</label>
                    <textarea class="form-control" formControlName="descripcion" rows="5"></textarea>
                    <div *ngIf="descripcion.hasError('required')" class="ng-invalid">
                      La descripción es obligatoria
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6 form-group">
                    <label>
                      Órgano o comité
                    </label>
                    <ng-select formControlName="idTipoOrgano" (ngModelChange)="TipoSeleccionado($event)" [required]="!paraSecretariaGeneral.value">
                      <ng-option *ngFor="let tipo of tiposOrganos" [value]="tipo.id">{{tipo.nombre}}</ng-option>
                    </ng-select>
                    <div *ngIf="idTipoOrgano.hasError('required')" class="ng-invalid">
                      El tipo de órgano es obligatorio
                    </div>
                  </div>
                  <div class="col-sm-6 form-group">
                    <label>
                      Departamento
                    </label>
                    <ng-select formControlName="idDepartamento" (ngModelChange)="DepartamentoSeleccionado($event)" [required]="!paraSecretariaGeneral.value">
                      <ng-option *ngFor="let departamento of departamentos" [value]="departamento.id">{{departamento.nombre}}</ng-option>
                    </ng-select>
                    <div *ngIf="idDepartamento.hasError('required')" class="ng-invalid">
                      El departamento es obligatorio
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col form-group">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="chkDesconozco" formControlName="paraSecretariaGeneral"
                        (ngModelChange)="DesconozcoSeleccionado($event)">
                      <label class="form-check-label" for="chkDesconozco">
                        Desconozco el órgano de destino
                      </label>
                    </div>
                    <ngb-alert *ngIf="paraSecretariaGeneral.value" [type]="'info'" [dismissible]="false">
                      Esta solicitud de Registro General Electrónico será enviada a la <strong>Secretaría General</strong> de esta Federación.
                    </ngb-alert>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <div class="btn-group btn-block">
                      <button class="btn btn-primary" type="submit" [disabled]="formFormulario.invalid">
                        SIGUIENTE
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>


      </div>
    </div>
  </div>
</div>
