import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageBase } from 'src/models/PageBase';
import { PersonasService } from 'src/services/PersonasController';
import { PersistentesService } from 'src/services/Persistentes';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  selector: 'app-identificacion',
  templateUrl: './identificacion.component.html',
  styleUrls: ['./identificacion.component.css']
})
export class IdentificacionComponent extends PageBase implements OnInit {

  public formComprobacion;
  public formDatos;
  private PersonasService: PersonasService;

  constructor(
    public _persistente: PersistentesService,
    public http: HttpClient,
    private router: Router,
    public toastService: HotToastService,
    @Inject('BASE_URL') baseUrl: string
  ) {
    super(http, toastService);
    this.PersonasService = new PersonasService(this.http, baseUrl);

    // Inicialización del formulario para la comprobación de datos
    this.formComprobacion = new FormGroup({
      identificador: new FormControl('', [Validators.required, Validators.minLength(8)]),
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  ngOnInit() {

  }

  get email() { return this.formComprobacion.get('email'); }
  get identificador() { return this.formComprobacion.get('identificador'); }
  get nombre() { return this.formDatos.get('nombre'); }
  get apellido1() { return this.formDatos.get('apellido1'); }
  get apellido2() { return this.formDatos.get('apellido2'); }
  get fechaNacimiento() { return this.formDatos.get('fechaNacimiento'); }
  get domicilio() { return this.formDatos.get('domicilio'); }
  get poblacion() { return this.formDatos.get('poblacion'); }
  get codigoPostal() { return this.formDatos.get('codigoPostal'); }
  get telefono() { return this.formDatos.get('telefono'); }

  //#region Métodos de la clase

  public ComprobarDatos(): void {
    if (this.formComprobacion != null && this.formComprobacion.valid) {
      this.cargando = true;
      this.PersonasService.comprobar('/' + this.identificador.value + '/' + this.email.value)
        .then((resultado: ModelosTS.PersonasSolicitudesRegistro) => {
          if (resultado != null) {
            this._persistente.persona = resultado;
          } else {
            this.Inicializacion(
              <string>this.identificador.value,
              <string>this.email.value
            );
          }
          this.EstablecerPersona(this._persistente.persona);
          this.cargando = false;
        })
        .catch(error => this.Error(error));
    }
  }

  private EstablecerPersona(persona: ModelosTS.PersonasSolicitudesRegistro): void {
    const desactivado: boolean = persona.id > 0 ? true : false;

    this.formDatos = new FormGroup({
      nombre: new FormControl({
        value: persona.nombre,
        disabled: desactivado
      }, Validators.required),
      apellido1: new FormControl({
        value: persona.apellido1,
        disabled: desactivado
      }, Validators.required),
      apellido2: new FormControl({
        value: persona.apellido2,
        disabled: desactivado
      }),
      fechaNacimiento: new FormControl({
        value: persona.fechaNacimiento,
        disabled: desactivado
      }, [
        Validators.required,
        Validators.pattern('[0-3]?[0-9]/[0-3]?[0-9]/(?:[0-9]{2})?[0-9]{2}')
      ]),
      domicilio: new FormControl({
        value: persona.domicilio,
        disabled: desactivado
      }, Validators.required),
      poblacion: new FormControl({
        value: persona.poblacion,
        disabled: desactivado
      }, Validators.required),
      codigoPostal: new FormControl({
        value: persona.codigoPostal,
        disabled: desactivado
      }, Validators.required),
      telefono: new FormControl({
        value: persona.telefono,
        disabled: desactivado
      }, Validators.required)
    });
  }

  public GuardarPersona(): void {
    if (this.formDatos.valid) {
      this.cargando = true;

      this._persistente.persona.nombre = <string>this.nombre.value;
      this._persistente.persona.apellido1 = <string>this.apellido1.value;
      this._persistente.persona.apellido2 = this.apellido2.value !== undefined
        ? <string>this.apellido2.value
        : '';
      this._persistente.persona.fechaNacimiento = <string>this.fechaNacimiento.value;
      this._persistente.persona.domicilio = <string>this.domicilio.value;
      this._persistente.persona.poblacion = <string>this.poblacion.value;
      this._persistente.persona.codigoPostal = <string>this.codigoPostal.value;
      this._persistente.persona.telefono = <string>this.telefono.value;

      this.PersonasService.post('', this._persistente.persona)
        .then((resultado: ModelosTS.PersonasSolicitudesRegistro) => {
          this._persistente.persona = resultado;
          console.log(resultado);
          this.router.navigateByUrl('formulario');

          this.cargando = false;
        })
        .catch(error => this.Error(error));
    } else if (this._persistente.persona.id > 0) {
      this.router.navigateByUrl('formulario');
    }
  }

  private Inicializacion(_identificador: string, _email: string): void {
    this._persistente.persona = {
      apellido1: '',
      apellido2: '',
      codigoPostal: '',
      domicilio: '',
      email: _email,
      fechaNacimiento: '',
      id: 0,
      identificador: _identificador,
      nombre: '',
      poblacion: '',
      telefono: ''
    };
  }

  //#region

}
