import { FirmaComponent } from './firma/firma.component';
import { ArchivosComponent } from './archivos/archivos.component';
import { LegalComponent } from './legal/legal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbAlertModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { InicioComponent } from './inicio/inicio.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { DepartamentosDeOrganosService } from '../services/DepartamentosDeOrganos';
import { SolicitudesRegistroService } from '../services/SolicitudesRegistroController';
import { TiposOrganosService } from '../services/TiposOrganosController';
import { IdentificacionComponent } from './identificacion/identificacion.component';
import { PersonasService } from 'src/services/PersonasController';
import { PersistentesService } from 'src/services/Persistentes';
import { FormularioComponent } from './formulario/formulario.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ToastService } from 'src/services/Toast-service';
import { HotToastModule } from '@ngneat/hot-toast';
@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    InicioComponent,
    IdentificacionComponent,
    FormularioComponent,
    LegalComponent,
    ArchivosComponent,
    FirmaComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: '', component: InicioComponent, pathMatch: 'full' },
      { path: 'identificacion', component: IdentificacionComponent },
      { path: 'formulario', component: FormularioComponent },
      { path: 'legal', component: LegalComponent },
      { path: 'archivos', component: ArchivosComponent },
      { path: 'firma', component: FirmaComponent }
    ]),
    NgbAlertModule,
    NgbProgressbarModule,
    NgSelectModule,
    NgxDropzoneModule,
    HotToastModule.forRoot({
      position: 'top-right'
    })
  ],
  providers: [
    DepartamentosDeOrganosService,
    PersistentesService,
    PersonasService,
    SolicitudesRegistroService,
    TiposOrganosService, 
    ToastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
