import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { PageBase } from 'src/models/PageBase';
import { DepartamentosDeOrganosService } from 'src/services/DepartamentosDeOrganos';
import { PersistentesService } from 'src/services/Persistentes';
import { TiposOrganosService } from 'src/services/TiposOrganosController';

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class FormularioComponent extends PageBase implements OnInit {
  public formFormulario;

  public departamentos: ModelosTS.DepartamentosDeOrganos[] = [];
  public tiposOrganos: ModelosTS.TiposOrganos[] = [];
  public DepartamentosDeOrganosService: DepartamentosDeOrganosService;
  public TiposOrganosService: TiposOrganosService;

  get descripcion() { return this.formFormulario.get('descripcion'); }
  get idTipoOrgano() { return this.formFormulario.get('idTipoOrgano'); }
  get idDepartamento() { return this.formFormulario.get('idDepartamento'); }
  get paraSecretariaGeneral() { return this.formFormulario.get('paraSecretariaGeneral'); }

  constructor(
    public _persistente: PersistentesService,
    public http: HttpClient,
    private router: Router,
    public toastService: HotToastService,
    @Inject('BASE_URL') baseUrl: string
  ) {
    super(http, toastService);
    this.DepartamentosDeOrganosService = new DepartamentosDeOrganosService(http, baseUrl);
    this.TiposOrganosService = new TiposOrganosService(http, baseUrl);

    // Carga de la lista de tipos de órganos
    this.TiposOrganosService.lista('')
      .then((resultado: ModelosTS.TiposOrganos[]) => {
        this.tiposOrganos = resultado;
      })
      .catch(error => this.Error(error));
  }

  ngOnInit() {
    // Comprobación de datos válidos
    if (this._persistente.persona === undefined) {
      this.router.navigateByUrl('identificacion');
    }
    this.Inicializacion();
  }

  // #region Métodos de la clase

  public Anterior(): void {
    this.router.navigateByUrl('identificacion');
  }

  public DepartamentoSeleccionado(valor: number): void {
    if (valor !== undefined) {
      this.formFormulario.get('paraSecretariaGeneral').setValue(false);
    }
  }

  public DesconozcoSeleccionado(valor: boolean): void {
    if (valor) {
      this.formFormulario.get('idTipoOrgano').setValue(undefined);
      this.formFormulario.get('idDepartamento').setValue(undefined);
    }
  }

  private Inicializacion(): void {
    this.formFormulario = new FormGroup({
      descripcion: new FormControl('', Validators.required),
      idTipoOrgano: new FormControl(''),
      idDepartamento: new FormControl(''),
      paraSecretariaGeneral: new FormControl('')
    });

    if (this._persistente.solicitud !== undefined) {
      this.formFormulario.get('descripcion').setValue(this._persistente.solicitud.descripcion);
      this.formFormulario.get('paraSecretariaGeneral').setValue(this._persistente.solicitud.paraSecretariaGeneral);

      if (this._persistente.solicitud.idTipoOrgano > 0) {
        this.formFormulario.get('idTipoOrgano').setValue(this._persistente.solicitud.idTipoOrgano);
        this.TipoSeleccionado(this._persistente.solicitud.idTipoOrgano, this._persistente.solicitud.idDepartamento);
      }
    }
  }

  /**
   * Se ha seleccionado un valor de tipo de órgano
   * @param valor Identificador del tipo de órgano seleccionado
   */
  // tslint:disable-next-line: no-unnecessary-initializer
  public TipoSeleccionado(valor: number, departamentoSeleccionado: number = undefined): void {
    if (valor !== undefined) {
      this.cargando = true;
      this.departamentos = [];
      this.formFormulario.get('paraSecretariaGeneral').setValue(false);
      this.formFormulario.get('idDepartamento').setValue(undefined);
      this.DepartamentosDeOrganosService.lista('/' + valor.toString())
        .then((resultado: ModelosTS.DepartamentosDeOrganos[]) => {
          this.departamentos = resultado;
          this.cargando = false;

          if (departamentoSeleccionado !== undefined) {
            this.formFormulario.get('idDepartamento').setValue(departamentoSeleccionado);
            this._persistente.solicitud.idDepartamento = undefined;
          }
        })
        .catch(error => this.Error(error));
    }
  }

  public Validar(): void {
    if (this.formFormulario.valid) {
      this.cargando = true;

      // Establecer los valores de la solicitud
      this._persistente.solicitud = {
        cifClub: '',
        codigoClub: 0,
        descripcion: this.descripcion.value,
        emailClub: '',
        emailPersona: this._persistente.persona.email,
        fecha: new Date(),
        id: 0,
        idApoderado: 0,
        idCalendario: 0,
        idClub: 0,
        idDepartamento: this.paraSecretariaGeneral.value === false ? this.idDepartamento.value : 0,
        idDirectivo: 0,
        idEstado: 1,
        idPersona: 0,
        idTemporada: 0,
        idTipoOrgano: this.paraSecretariaGeneral.value === false ? this.idTipoOrgano.value : 0,
        idUsuarioActivo: 0,
        identificadorDirectivo: '',
        identificadorPersona: this._persistente.persona.identificador,
        localizador: '',
        nombreClub: '',
        nombreDirectivo: '',
        nombreEstado: '',
        nombrePersona: '',
        numeroExpediente: '',
        numeroRegistro: '',
        paraSecretariaGeneral: this.paraSecretariaGeneral.value
      };

      if (this.paraSecretariaGeneral.value === false && this.idTipoOrgano.value > 0) {
        this._persistente.tipoOrgano = this.tiposOrganos.filter(m => m.id === this.idTipoOrgano.value)[0].nombre;
      }

      if (this.paraSecretariaGeneral.value === false && this.idDepartamento.value > 0) {
        this._persistente.departamento = this.departamentos.filter(m => m.id === this.idDepartamento.value)[0].nombre;
      }

      this.router.navigateByUrl('legal');
    }
  }
  // #endregion
}
