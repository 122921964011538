export class PersistentesService {

  public persona: ModelosTS.PersonasSolicitudesRegistro;
  public solicitud: ModelosTS.SolicitudesRegistro;
  public archivos: ModelosTS.ArchivosSolicitudesRegistro[] = [];
  public tipoOrgano = '';
  public departamento = '';

  constructor() {

  }

  public ArchivosPDF(): ModelosTS.ArchivosSolicitudesRegistro[] {
    return this.archivos.filter(m => m.mimeType === 'application/pdf');
  }

  /**
   * Número de archivos que no son PDF
   * @returns number
   */
  public NumeroArchivosOtros(): number {
    return this.archivos.length > 0
      ? this.archivos.filter(m => m.mimeType !== 'application/pdf').length
      : 0;
  }

  /**
   * Número de archivos que son PDF
   * @returns number
   */
  public NumeroArchivosPDF(): number {
    return this.archivos.length > 0
      ? this.archivos.filter(m => m.mimeType === 'application/pdf').length
      : 0;
  }

  public OtrosArchivos(): ModelosTS.ArchivosSolicitudesRegistro[] {
    return this.archivos.filter(m => m.mimeType !== 'application/pdf');
  }
}
