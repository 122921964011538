<div class="hk-pg-wrapper" *ngIf="configuraciones != undefined">
  <div class="container">
    <div class="row">
      <div class="col">

        <ngb-progressbar type="primary" [value]="100" [striped]="true" *ngIf="cargando">... cargando ...</ngb-progressbar>

        <section class="hk-sec-wrapper">
          <div class="row">
            <div class="col-sm">
              <h2 class="col-8 mb-20">
                Paso 1. Identificación y contacto
              </h2>
              <p class="mb-20">
                Indique sus datos personales y de contacto. Los campos marcados con (*) son campos obligatorios.
              </p>
            </div>
          </div>

          <!-- Formulario de comprobación de datos -->
          <hr *ngIf="_persistente.persona == undefined"/>
          <div class="row" *ngIf="_persistente.persona == undefined">
            <div class="col-sm">
              <form [formGroup]="formComprobacion" (ngSubmit)="ComprobarDatos()">
                <div class="row">
                  <div class="col-sm-6 form-group">
                    <label>
                      Identificador (DNI/NIE) (*):
                    </label>
                    <input type="text" class="form-control" formControlName="identificador">
                    <div *ngIf="identificador.hasError('required')" class="ng-invalid">
                      El identificador es obligatorio
                    </div>
                    <div *ngIf="identificador.hasError('minlength')" class="ng-invalid">
                      Debe tener como mínimo 8 caracteres
                    </div>
                  </div>
                  <div class="col-sm-6 form-group">
                    <label>
                      Correo electrónico (*):
                    </label>
                    <input type="email" class="form-control" formControlName="email" />
                    <div *ngIf="email.hasError('required')" class="ng-invalid">
                      El correo electrónico es obligatorio
                    </div>
                    <div *ngIf="email.hasError('email')" class="ng-invalid">
                      Debe ser una dirección email válida
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm">
                    <button class="btn btn-primary btn-block"
                            [disabled]="formComprobacion.invalid || cargando">
                      COMPROBAR DATOS
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <!-- Formulario con los datos de la persona -->
          <hr *ngIf="_persistente.persona != undefined"/>
          <div class="row" *ngIf="_persistente.persona != undefined">
            <div class="col-sm">
              <ngb-alert *ngIf="_persistente.persona.id > 0" [type]="'success'" [dismissible]="false">
                Persona encontrada para el identificador <strong>{{_persistente.persona.identificador}}</strong> y el email <strong>{{_persistente.persona.email}}</strong>
              </ngb-alert>
              <form [formGroup]="formDatos" (ngSubmit)="GuardarPersona()">
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label>
                      Nombre (*)
                    </label>
                    <input type="text" class="form-control" formControlName="nombre">
                    <div *ngIf="nombre.hasError('required')" class="ng-invalid">
                      El nombre es obligatorio
                    </div>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>
                      Apellido 1 (*)
                    </label>
                    <input type="text" class="form-control" formControlName="apellido1">
                    <div *ngIf="nombre.hasError('required')" class="ng-invalid">
                      El apellido 1 es obligatorio
                    </div>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>
                      Apellido 2
                    </label>
                    <input type="text" class="form-control" formControlName="apellido2">
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 form-group">
                    <label>
                      Domicilio (*)
                    </label>
                    <input type="text" class="form-control" formControlName="domicilio">
                    <div *ngIf="domicilio.hasError('required')" class="ng-invalid">
                      El domicilio es obligatorio
                    </div>
                  </div>
                  <div class="col-md-3 form-group">
                    <label>
                      Población (*)
                    </label>
                    <input type="text" class="form-control" formControlName="poblacion">
                    <div *ngIf="poblacion.hasError('required')" class="ng-invalid">
                      La población es obligatoria
                    </div>
                  </div>
                  <div class="col-md-3 form-group">
                    <label>
                      Código postal (*)
                    </label>
                    <input type="text" class="form-control" formControlName="codigoPostal">
                    <div *ngIf="codigoPostal.hasError('required')" class="ng-invalid">
                      El código postal es obligatorio
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6 form-group">
                    <label>
                      Teléfono (*)
                    </label>
                    <input type="text" class="form-control" formControlName="telefono">
                    <div *ngIf="telefono.hasError('required')" class="ng-invalid">
                      El teléfono es obligatorio
                    </div>
                  </div>
                  <div class="col-sm-6 form-group">
                    <label>
                      Fecha de nacimiento (*)
                    </label>
                    <input type="text" class="form-control" formControlName="fechaNacimiento">
                    <div *ngIf="fechaNacimiento.hasError('required')" class="ng-invalid">
                      La fecha de nacimiento es obligatoria
                    </div>
                    <div *ngIf="fechaNacimiento.hasError('pattern')" class="ng-invalid">
                      El formato de la fecha no es correcto
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <button class="btn btn-primary btn-block"
                            [disabled]="formDatos.invalid || cargando">
                      SIGUIENTE
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

      </div>
    </div>
  </div>
</div>
