import { PageBase } from 'src/models/PageBase';
import { Component, Inject, OnInit } from '@angular/core';
import { PersistentesService } from 'src/services/Persistentes';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { ArchivosService } from 'src/services/ArchivosController';
import { HotToastService } from '@ngneat/hot-toast';

interface IUploadFichero {
  ruta: string;
  nombre: string;
}

interface IEliminarFichero {
  respuesta: boolean;
}

@Component({
  selector: 'app-archivos',
  templateUrl: './archivos.component.html',
  styleUrls: ['./archivos.component.css']
})
export class ArchivosComponent extends PageBase implements OnInit {

  private static readonly _extensiones: string[] = ['MOV', 'MP4', 'AVI', 'MPG', 'MP3', 'WAV', 'AIFF', 'JPG', 'JPEG', 'PNG'];
  private numeroArchivosMax = 5;

  public archivosPDF: File[] = [];
  public otrosArchivos: File[] = [];

  private ArchivosService: ArchivosService;

  constructor(
    public _persistente: PersistentesService,
    public http: HttpClient,
    private router: Router,
    public toastService: HotToastService,
    @Inject('BASE_URL') baseUrl: string
  ) {
    super(http, toastService);
    this.ArchivosService = new ArchivosService(http, baseUrl);
  }

  ngOnInit() {
    // Comprobación de datos válidos
    if (this._persistente.persona === undefined || this._persistente.solicitud === undefined) {
      this.router.navigateByUrl('identificacion');
    }
  }

  //#region Métodos de la clase
  public Anterior(): void {
    this.router.navigateByUrl('legal');
  }

  private GenerarArchivo(fichero: File): Promise<boolean | void> {
    const formData = new FormData();

    formData.append('file', fichero, fichero.name);

    return this.ArchivosService.upload('', formData)
      .then((resultado: IUploadFichero) => {
        const datoArchivo: ModelosTS.ArchivosSolicitudesRegistro = {
          fechaBackup: '',
          id: 0,
          idClub: 0,
          idSolicitudRegistro: 0,
          mimeType: this.TipoArchivo(this.Extension(fichero.name)),
          nombre: fichero.name,
          remoto: false,
          rutaBackup: '',
          rutaOriginal: resultado.ruta
        };
        if (this._persistente.archivos.filter(m => m.nombre === fichero.name).length === 0) {
          this._persistente.archivos.push(datoArchivo);
        }

        return true;
      })
      .catch(error => this.Error(error));
  }

  /**
   * Procesa los archivos seleccionados y pasa al siguiente paso
   */
  public Siguiente(): void {
    this.router.navigateByUrl('firma');
  }

  private TipoArchivo(extension: string): string {
    let resultado: string = '';

    switch (extension.toUpperCase()) {
      case 'PDF':
        resultado = 'application/pdf';
        break;
      case 'MOV':
        resultado = 'video/quicktime';
        break;
      case 'MP4':
        resultado = 'video/mp4';
        break;
      case 'AVI':
        resultado = 'video/x-msvideo';
        break;
      case 'MPG':
        resultado = 'video/mpeg';
        break;
      case 'MP3':
        resultado = 'audio/mpeg';
        break;
      case 'WAV':
        resultado = 'audio/wav';
        break;
      case 'AIFF':
        resultado = 'audio/aiff';
        break;
      case 'JPG':
      case 'JPEG':
        resultado = 'image/jpg';
        break;
      case 'PNG':
        resultado = 'image/png';
        break;
    }

    return resultado;
  }

  //#endregion

  //#region Métodos relacionados con el componente DropZone

  /**
   * Devuelve la extensión del archivo
   * @param nombre Nombre del archivo
   * @returns Externsión en mayúsculas del archivo
   */
  public Extension(nombre: string): string {
    // tslint:disable-next-line: no-inferrable-types
    let resultado: string = 'EXT';

    if (nombre !== undefined && nombre.trim().length > 0) {
      const aux: string[] = nombre.split('.');

      if (aux.length > 0) {
        resultado = aux[aux.length - 1].toUpperCase();
      }
    }
    return resultado;
  }

  public onSelect(event: NgxDropzoneChangeEvent, lista: string) {
    const promesas: Promise<boolean | void>[] = [];

    if (lista === 'pdf') {
      // Procesar el fichero para los del tipo pdf

      // Se comprueba si hay archivos rechazados, para informarlo como alerta ...
      event.rejectedFiles.forEach((valor: File) => {
        this.MensajeAviso(valor.name + ' - Fichero no válido');
      });

      // ... se comprueba si se pueden añadir todos los archivos, según el número de archivos existentes.
      if (this.numeroArchivosMax - this._persistente.NumeroArchivosPDF() > event.addedFiles.length) {
        event.addedFiles.forEach((fichero: File) => {
          promesas.push(this.GenerarArchivo(fichero));
        });

        Promise.all(promesas)
          .then((resultado: any) => {
            this.MensajeExito('Fichero/s correctamente incluídos');
          })
          .catch(error => this.Error(error));
      } else {
        this.MensajeAviso('El número de archivos a incluir, supera el límite de ' + this.numeroArchivosMax.toString());
      }
    } else if (lista === 'otros') {
      // Se comprueba si hay archivos rechazados, para informarlo como alerta ...
      event.rejectedFiles.forEach((valor: File) => {
        this.MensajeAviso(valor.name + ' - Fichero no válido');
      });

      // ... se comprueba si se pueden añadir todos los archivos, según el número de archivos existentes.
      if (this.numeroArchivosMax - this._persistente.NumeroArchivosOtros() > event.addedFiles.length) {
        event.addedFiles.forEach((fichero: File) => {
          // Se comprueba si el archivo tiene una extensión válida.
          const partesArchivo: string[] = fichero.name.split('.');
          const extensionArchivo = partesArchivo[partesArchivo.length - 1];

          if (ArchivosComponent._extensiones.indexOf(extensionArchivo.toUpperCase()) >= 0) {
            promesas.push(this.GenerarArchivo(fichero));
          } else {
            this.MensajeAviso('El archivo ' + fichero.name + ' no tiene un tipo de fichero válido.');
          }
        });

        if (promesas.length > 0) {
          Promise.all(promesas)
            .then((resultado: any) => {
              this.MensajeExito('Fichero/s correctamente incluídos');
            })
            .catch((err) => this.Error(err));
        }
      } else {
        this.MensajeAviso('El número de archivos a incluir, supera el límite de ' + this.numeroArchivosMax.toString());
      }
    }
  }

  public onRemove(archivo: ModelosTS.ArchivosSolicitudesRegistro) {
    console.log(archivo);
    // Eliminación del fichero en la lista de ficheros por subir
    this.ArchivosService.eliminar('/' + archivo.rutaOriginal)
      .then((resultado: IEliminarFichero) => {
        if (resultado.respuesta) {
          this._persistente.archivos.splice(this._persistente.archivos.indexOf(archivo), 1);
        }
      })
      .catch(error => this.Error(error));
  }
  //#endregion

}
