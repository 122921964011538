
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

  @Injectable()
  export class TiposOrganosService {
    private http:HttpClient;
    private baseUrl: string;
    constructor(_http: HttpClient, _baseUrl: string) {
      this.http = _http;
      this.baseUrl = _baseUrl;
    }
    
    public lista = (params:any) : Promise<any> => {
      return this.http.get(this.baseUrl + 'tiposorganos/lista' + params).toPromise()
        .then(result => {
          return result;
        })
        .catch(error => Promise.reject(error));
    }
    
    public post = (paramsUrl:any,params:any) : Promise<any> => {
      return this.http.post(this.baseUrl + 'tiposorganos/post' + paramsUrl, params).toPromise()
        .then(result => {
          return result;
        })
        .catch(error => Promise.reject(error));
    }
    
  }

